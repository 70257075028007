import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { client } from "../../client";
import "../css/entry.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
// import SideNav from './SideNav'
import JournalFooter from "./JournalFooter";
import format from "date-fns/format";

const Entry = () => {
  const { slug } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [page, pageData] = useState([]);

  const cleanPost = useCallback((rawData) => {
    const cleanedPost = {
      postTitle: rawData.fields.title,
      postDate: format(
        new Date(
          rawData.fields.postDate
            ? rawData.fields.postDate
            : rawData.sys.updatedAt
        ),
        "PPPP"
      ),
      postBG: rawData.fields.hero.fields.file.url,
      id: rawData.sys.id,
      postContent: rawData.fields.blogContent,
    };
    pageData(cleanedPost);
  }, []);

  const getPageData = useCallback(async () => {
    setIsPageLoading(true);
    try {
      const response = await client.getEntries({
        content_type: "blogPost",
        "fields.slug": slug,
      });
      const responseData = response.items[0];
      if (responseData) {
        cleanPost(responseData);
      } else {
        pageData([]);
      }
      setIsPageLoading(false);
    } catch (error) {
      setIsPageLoading(false);
    }
  }, [cleanPost]);

  useEffect(() => {
    getPageData();
  }, [getPageData]);

  const richTextOptions = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a
            href={`${node.data.uri}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {node.content[0].value}
          </a>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return (
          <img
            src={`https://${node.data.target.fields.file.url}`}
            style={{ maxWidth: "100%" }}
            alt={node.data.target.fields.description}
          />
          // <div className='entryImg' style={{backgroundImage: `url(${node.data.target.fields.file.url})`}} />
        );
      },
    },
  };

  return isPageLoading ? (
    <div>not done</div>
  ) : (
    <div id="Entry" className="page">
      {/* <SideNav/> */}
      <div className="hero" style={{ backgroundImage: `url(${page.postBG})` }}>
        <div className="text-wrapper">
          <h1 className="title">{page.postTitle}</h1>
          <p className="date">{page.postDate}</p>
        </div>
      </div>
      <div className="content">
        {page.postContent
          ? documentToReactComponents(page.postContent, richTextOptions)
          : console.log("loading")}
      </div>
      <JournalFooter />
    </div>
  );
};

export default Entry;
