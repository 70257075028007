// import React, { useState } from 'react'
import Error from "../error";
import RRH from "../projects/RRH";
import Envoy from "../projects/Envoy";
import Connections from "../projects/Connections";

// const [selectedProject, getSelectedProject] = useState('')

const ProjectSelector = (item) => {
  // getSelectedProject(index)
  if (item.projTitle === "Wayfinder") {
    return <RRH props={item} />;
  } else if (item.projTitle === "envoy") {
    return <Envoy props={item} />;
  } else if (item.projTitle === "Connections") {
    return <Connections props={item} />;
  } else {
    return <Error />;
  }
};

const ProjectLoader = (props) => {
  return (
    <div id="projectContent">
      {
        // console.log(props.props.order)
        ProjectSelector(props.props)
      }
    </div>
  );
};

export default ProjectLoader;
