import "./css/main.css";
import "./css/project.css";
import React, { useCallback, useEffect, useState } from "react";
import { client } from "../client";
import { useParams } from "react-router-dom";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import Error from "./error";
import ProjectLoader from "./components/ProjectLoader";

const Project = () => {
  const { slug } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isPageData, setIsPageData] = useState(true);
  const [page, pageData] = useState([]);

  const cleanData = useCallback((rawData) => {
    const cleanedData = {
      projTitle: rawData.fields.projectTitle,
      projHero: rawData.fields.heroImage.fields.file.url,
      projColor: rawData.fields.projectColor,
      projAbstract: rawData.fields.projectAbstract,
      projDesc: rawData.fields.projectDesc,
      projRoles: rawData.fields.projectRoles,
      id: rawData.sys.id,
      projContent: rawData.fields.projectContent,
      order: rawData.fields.order,
    };
    pageData(cleanedData);
  }, []);

  const getPageData = useCallback(async () => {
    setIsPageLoading(true);
    try {
      const response = await client.getEntries({
        content_type: "project",
        "fields.slug": slug,
      });
      const responseData = response.items[0];
      if (responseData) {
        cleanData(responseData);
      } else {
        pageData([]);
        setIsPageData(false);
      }
      setIsPageLoading(false);
    } catch (error) {
      setIsPageLoading(false);
    }
  }, [cleanData]);

  // console.log(page)

  useEffect(() => {
    getPageData();
  }, [getPageData]);

  const richTextOptions = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a
            href={`${node.data.uri}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {node.content[0].value}
          </a>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return (
          <img
            src={`https://${node.data.target.fields.file.url}`}
            width={"100%"}
            alt={node.data.target.fields.description}
          />
          // <div className='entryImg' style={{backgroundImage: `url(${node.data.target.fields.file.url})`}} />
        );
      },
    },
  };

  return isPageLoading ? (
    <div>loading</div>
  ) : isPageData ? (
    <div id="Project">
      <div className="hero" style={{ backgroundColor: page.projColor }}>
        <img src={page.projHero} alt={page.projTitle + " hero"} />
      </div>
      <div className="intro">
        <div className="container page-margin">
          <div className="projTitle">
            <h2>{page.projTitle}</h2>
            <p>
              <b>{page.projDesc}</b>
            </p>
          </div>
          <div className="projDesc">
            <h5 style={{ color: page.projColor }}>Abstract</h5>
            <div>
              {page.projAbstract
                ? documentToReactComponents(page.projAbstract, richTextOptions)
                : ""}
            </div>
            <h5 className="projRolesHeader" style={{ color: page.projColor }}>
              Roles
            </h5>
            <div className="projRoles">
              {page.projRoles
                ? page.projRoles.map((role) => {
                    return (
                      <p key={page.projRoles.indexOf(role)}>
                        <b>{role}</b>
                      </p>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </div>
      <div className="mainContent">
        <ProjectLoader props={page ? page : ""} />
      </div>
    </div>
  ) : (
    <Error />
  );
};

export default Project;
