import React from "react";
import "../css/navbar.css";
import "../css/main.css";
import Logo from "../../assets/Logo.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="container page-margin">
        <Link to="/">
          <img src={Logo} alt="Logo" style={{ width: "40px" }} />
        </Link>
        <ul>
          <li>
            <Link to="/">work</Link>
          </li>
          <li>
            <Link to="/about">about</Link>
          </li>
          <li className="last">
            <Link to="/journal">journal</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
