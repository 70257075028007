import "./css/main.css";
import "./css/about.css";
import React, { Component } from "react";
import Profile from "../assets/profile.jpeg";
import Jobhistory from "./components/Jobhistory";

class About extends Component {
  render() {
    return (
      <div id="About" className="page page-margin">
        <div className="hero">
          <div className="image">
            <div
              className="profile"
              style={{
                backgroundImage: `url(${Profile})`,
              }}
            ></div>
          </div>
          <div className="about-text">
            <h1 className="primary-color">Alex who?</h1>
            <div className="text">
              <p>
                My name is Alexander Kim, but just call me Alex! I love designs
                that are clean and minimal, but I also love to experiment with
                new style and learning new skills and tricks to keep me up to
                date with trends.
              </p>
              <p>
                However, Design isn't the only thing that gets me excited! I
                love playing video games, illustrating characters and eating
                copious amounts of food.
              </p>
              <div className="contact">
                <p>Like what you see? Shoot my an email at </p>
                <p>
                  <b>alexkim721@gmail.com!</b>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="work-history">
          <div className="header">
            <h2 className="primary-color">What I've been up to</h2>
            <p className="button">
              <a
                className="primary-color"
                href={require("../assets/resume.pdf")}
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Full Resume</b>
              </a>
            </p>
          </div>
          <Jobhistory />
        </div>
        <div id="interests">
          <div className="header">
            <h2 className="primary-color">Things I like to work on</h2>
            <p className="desc">
              While design is my primary discipline, I love to dabble in
              illustration as I have a background in fine arts. In addition, I
              love building my projects through code.
            </p>
          </div>
          <ul>
            <li>
              <b>UI Design</b>
            </li>
            <li>
              <b>UX Design</b>
            </li>
            <li>
              <b>Illustration</b>
            </li>
            <li>
              <b>Design Systems</b>
            </li>
            <li>
              <b>Front End Development</b>
            </li>
            <li>
              <b>Motion Graphics</b>
            </li>
            <li>
              <b>Prototyping</b>
            </li>
            <li>
              <b>Product Design</b>
            </li>
            <li>
              <b>Teaching</b>
            </li>
            <li>
              <b>Visual Design</b>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default About;
