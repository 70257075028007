import './css/main.css';
import './css/home.css';
import React, {Component} from 'react';
import JournalFooter from './components/JournalFooter';
import ProjectBanners from './components/ProjectBanners';

class Home extends Component {
    render(){
        return (
            <div id='Home' className='page-margin page'>
                <section id='hero'>
                    <h1 className='primary-color'>
                        Hi, I'm Alex!
                    </h1>
                    <h3>
                        I'm a UX and UI designer with a penchant for illustration and code.
                    </h3>
                </section>
                <section id='projects'>
                    <ProjectBanners/>
                    {/* <div className='project'>
                        <div className='image'/>
                        <div className='project-text'>
                            <h2>Project Title</h2>
                            <div className='project-roles'>
                                <p><b>UX Design</b></p>
                                <p><b>UX Design</b></p>
                                <p className='last'><b>UX Design</b></p>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sit amet ornare urna, vel laoreet neque.</p>
                        </div>
                    </div>
                    <div className='project last'>
                        <div className='image'/>
                        <div className='project-text'>
                            <h2>Project Title</h2>
                            <div className='project-roles'>
                                <p><b>UX Design</b></p>
                                <p><b>UX Design</b></p>
                                <p className='last'><b>UX Design</b></p>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sit amet ornare urna, vel laoreet neque.</p>
                        </div>
                    </div> */}
                </section>
                <section id='journal'>
                    <div className='journal-header'>
                        <h2 className='primary-color'>
                            What I've been thinking about
                        </h2>
                           <a href='/journal'><b>More Entries</b></a>
                    </div>
                    <JournalFooter/>
                </section>
            </div>
        );
    }
}

export default Home;