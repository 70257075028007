import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Carousel from "../components/Carousel";

const Envoy = (props) => {
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const settingsApp = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
  };
  const projColor = props.props.projColor;
  return (
    <div>
      <section id="problem" className="projWidth">
        <h2>Problem</h2>
        <p>
          The recovery process can be a tough journey. Without the proper
          resources and guidance, even starting becomes a daunting task.
        </p>
        <p>
          There's an overwhelming feeling of isolation and helplessness that
          comes when faced with this problem when proper help can't be reached.
        </p>
        <div className="fullcolmimage" style={{ backgroundColor: projColor }}>
          <svg
            width="100"
            height="100"
            viewBox="0 0 680 680"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M340 0C152.22 0 0 152.23 0 340C0 527.78 152.22 680 340 680C527.78 680 680 527.78 680 340C680.01 152.23 527.78 0 340 0ZM340 578.006C208.557 578.006 101.994 471.453 101.994 340C101.994 208.557 208.547 101.994 340 101.994C471.453 101.994 578.006 208.557 578.006 340C578.006 471.453 471.453 578.006 340 578.006Z"
              fill="white"
            />
            <path
              d="M438.889 157.985L291.04 293.047C279.51 303.579 271.648 317.522 268.602 332.84L229.551 529.254C228.236 535.869 236.137 540.329 241.121 535.78L388.97 400.718C400.5 390.186 408.362 376.243 411.408 360.925L450.459 164.512C451.774 157.886 443.873 153.426 438.889 157.985ZM376.856 348.989C376.184 352.005 372.792 353.518 370.092 352.005L320.044 323.782C317.355 322.259 316.89 318.581 319.125 316.435L401.707 237.432L376.856 348.989Z"
              fill="white"
            />
          </svg>
        </div>
        <p>
          Envoy is a recovery app meant to give resources to users who may need
          an intervention to start their recovery journey. It also allows users
          to actively chat with their doctors or participate in group
          discussions with others who may be experiencing something similar.
        </p>
        <h4>Goals</h4>
        <ul className="fullColmList">
          <li>
            <h5 style={{ color: projColor }}>01</h5>
            <h6>
              Create an intuitive and clean app that users can easily navigate
              through.
            </h6>
          </li>
          <li>
            <h5 style={{ color: projColor }}>02</h5>
            <h6>
              Have user driven resources that make users reflect on their own
              recovery.
            </h6>
          </li>
          <li>
            <h5 style={{ color: projColor }}>03</h5>
            <h6>Promote a friendly environment to ease users into recovery.</h6>
          </li>
        </ul>
        <h4>User Flow</h4>
        <img src={require("../../assets/envoy/userflow.png")} />
      </section>
      <section id="designs">
        <div className="projWidth">
          <h2>Designs</h2>
          <p>
            The designs for this project were particularly important because
            they were meant to serve as the blueprints for future projects. With
            that in mind, I wanted to make sure I decided on a consistent and
            pleasant design language that I could later introduce to Connections
            when it got its own facelift.
          </p>
          <div className="fullcolmimage" style={{ backgroundColor: projColor }}>
            <img src={require("../../assets/envoy/icon-message.png")} />
            <img src={require("../../assets/envoy/icon-providers.png")} />
            <img src={require("../../assets/envoy/icon-videos.png")} />
            <img src={require("../../assets/envoy/icon-think.png")} />
          </div>
          <p>
            I used softer colors with rounded boxes and colorful illustrations
            to create a friendlier atmosphere.
          </p>
          <h6>Onboarding</h6>
          <p>
            As the main introduction to the app, I wanted to set the tone
            immediately for users. I used cute illustrations that connected with
            a line to tie each slide together. It was also a fun way to
            visualize each main section of the app.
          </p>
        </div>
        <div className="widecolm projWidth">
          <img src={require("../../assets/envoy/Onboarding.png")} />
          <img src={require("../../assets/envoy/Onboarding-1.png")} />
          <img src={require("../../assets/envoy/Onboarding-2.png")} />
          <img src={require("../../assets/envoy/Onboarding-3.png")} />
          <img src={require("../../assets/envoy/Onboarding-4.png")} />
        </div>
        <div className="twocolum projWidth">
          <div className="iphoneMock">
            {/* <img
              alt="iphone mock"
              src={require("../../assets/iphone-flat-mock.png")}
              className="iphone"
            /> */}
            {/* <Carousel>
              <img src={require("../../assets/envoy/Onboarding.png")} />
              <img src={require("../../assets/envoy/Onboarding-1.png")} />
              <img src={require("../../assets/envoy/Onboarding-2.png")} />
              <img src={require("../../assets/envoy/Onboarding-3.png")} />
              <img src={require("../../assets/envoy/Onboarding-4.png")} />
            </Carousel> */}
            <Slider {...slickSettings}>
              <img src={require("../../assets/envoy/Home.png")} />
              <img src={require("../../assets/envoy/Side Menu.png")} />
            </Slider>
            {/* <img src={require("../../assets/envoy/Onboarding.png")} /> */}
          </div>
          <div className="text">
            <h6>Homepage</h6>
            <p>
              For the homepage, I paid additional attention to the design, as
              the wireframes for the original Connections were repurposed for
              this project. A similar floating card design was adopted into
              Connections.
            </p>
          </div>
        </div>
        <div className="twocolum projWidth reverse">
          <div className="iphoneMock">
            {/* <img
              alt="iphone mock"
              src={require("../../assets/iphone-flat-mock.pn")}
              className="iphone"
            /> */}
            <Slider {...slickSettings}>
              <img src={require("../../assets/envoy/Message.png")} />
              <img src={require("../../assets/envoy/Message-1.png")} />
              <img src={require("../../assets/envoy/Message-2.png")} />
              <img src={require("../../assets/envoy/Message-3.png")} />
            </Slider>
          </div>
          <div className="text">
            <h6>Message</h6>
            <p>
              The Message section lets patients interact with their providers
              and participate in group discussions with others who may be in the
              recovery process.
            </p>
          </div>
        </div>
        <div className="twocolum projWidth">
          <div className="iphoneMock">
            {/* <img
              alt="iphone mock"
              src={require("../../assets/iphone-flat-mock.pn")}
              className="iphone"
            /> */}
            <Slider {...slickSettings}>
              <img src={require("../../assets/envoy/Providers.png")} />
              <img src={require("../../assets/envoy/Providers-1.png")} />
            </Slider>
          </div>
          <div className="text">
            <h6>Providers</h6>
            <p>
              Here, users can check the contact information and locations of
              providers for quick access to resources.
            </p>
          </div>
        </div>
        <div className="twocolum projWidth reverse">
          <div className="iphoneMock">
            {/* <img
              alt="iphone mock"
              src={require("../../assets/iphone-flat-mock.pn")}
              className="iphone"
            /> */}
            <Slider {...slickSettings}>
              <img src={require("../../assets/envoy/Video-1.png")} />
              <img src={require("../../assets/envoy/Video-2.png")} />
              <img src={require("../../assets/envoy/Video.png")} />
              <img src={require("../../assets/envoy/Video-3.png")} />
            </Slider>
          </div>
          <div className="text">
            <h6>Video</h6>
            <p>
              CHESS health has a variety of video resources that can describe
              the recovery process. By allowing the user to learn about the
              journey, we were hoping to get users more comfortable with the
              idea of going through with recovery as well as dispelling any
              potential myths.
            </p>
            <p>
              We also added a feedback option for users to give input on how
              some of the content made them feel for our front facing team to
              use to better understand what videos we should look for in the
              future.
            </p>
          </div>
        </div>
        <div className="twocolum projWidth">
          <div className="iphoneMock">
            {/* <img
              alt="iphone mock"
              src={require("../../assets/iphone-flat-mock.pn")}
              className="iphone"
            /> */}
            <Slider {...slickSettings}>
              <img src={require("../../assets/envoy/Think-1.png")} />
              <img src={require("../../assets/envoy/Think-2.png")} />
              <img src={require("../../assets/envoy/Think-3.png")} />
              <img src={require("../../assets/envoy/Think-4.png")} />
              <img src={require("../../assets/envoy/Think-6.png")} />
            </Slider>
          </div>
          <div className="text">
            <h6>Think</h6>
            <p>
              This was the final section created as an ad hoc idea for extra
              user driven content. We wanted a fun section for users to test
              their knowledge on recovery in an interactive way. It would also
              give providers a chance to create their own interactive content
              for users in the future.
            </p>
          </div>
        </div>
      </section>
      <section id="marketing" className="projWidth">
        <h2>Marketing</h2>
        <p>
          For starters, we wanted to create a small postcard sized flier that
          could easily and quickly explain envoy and its core features. That
          way, providers could have a physical hand out that they could give to
          potential users.
        </p>
        <img
          src={require("../../assets/envoy/Postcard.png")}
          style={{ border: "1px solid #f5f5f5", margin: "20px 0" }}
        />
        <p>
          For the app store, I created some fun, connecting slide designs that
          showcased some of the key features in the app. I also used them to
          show the friendly vibe of the app.
        </p>
        <Slider {...settingsApp}>
          <img src={require("../../assets/envoy/AppStore-0.png")} />
          <img src={require("../../assets/envoy/AppStore-1.png")} />
          <img src={require("../../assets/envoy/AppStore-2.png")} />
          <img src={require("../../assets/envoy/AppStore-4.png")} />
          <img src={require("../../assets/envoy/AppStore-3.png")} />
          <img src={require("../../assets/envoy/AppStore-5.png")} />
        </Slider>
      </section>
      <section id="development" className="projWidth">
        <h2>Development</h2>
        <p>
          The last piece to this project was something that came as a self
          driven project, as it wasn't requested to be done. Envoy has custom
          buttons on the homepage that can be customized by providers to match
          their brand. The front-facing team regularly gave demos of envoy to
          potential customers and wanted to really focus on customization for
          providers so they would regularly come to me to create a button design
          for the page.
        </p>
        <div className="widecolm projWidth" style={{ margin: "40px 0" }}>
          <img
            src={require("../../assets/envoy/home-button-0.png")}
            style={{ borderRadius: "8px" }}
          />
          <img
            src={require("../../assets/envoy/home-button-1.png")}
            style={{ borderRadius: "8px" }}
          />
          <img
            src={require("../../assets/envoy/home-button-2.png")}
            style={{ borderRadius: "8px" }}
          />
          <img
            src={require("../../assets/envoy/home-button-3.png")}
            style={{ borderRadius: "8px" }}
          />
        </div>
        <p>
          Eventually, I realized that this process could be automated, and thus
          I requested to create a tool that would automate the process and got
          approved.
        </p>

        <p>
          The first step was looking at changes needed between button content.
          The logo and the text content were always different so I started with
          those. I created a simple HTML page that would take in an image and a
          text string and generate a rendered image of what the button would
          look like. Then I got requests for the ability to change some of the
          colors to further match the provider's brand. Finally, I added some
          more color options to fully customize the button and a download button
          below the rendered image so users could download the image.
        </p>
        <img
          src={require("../../assets/envoy/button-generator.png")}
          style={{ border: "1px solid #f5f5f5", margin: "20px 0" }}
        />
        <p>
          It was a simple project that allowed me to freshen up on my coding and
          create a tool that could be used to future-proof the app further by
          creating consistently designed buttons.
        </p>
      </section>
      <section id="conclusion" className="projWidth">
        <h2>Conclusion</h2>
        <p>
          Envoy was my first big project that I had complete agency over as the
          sole designer. I focused on a clean and clear user experience while
          using colorful illustrations to liven up the designs. I took the
          chance to show what our flagship product, Connections, could look
          like.
        </p>
        <p>
          If I had the chance, there are certain aspects I would further improve
          on, such as better navigation and searching through videos and a
          slightly updated color scheme. However, I am very proud of this
          project and learned a lot while working on it.
        </p>
      </section>
    </div>
  );
};

export default Envoy;
