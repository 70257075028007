import "./css/main.css";
import "./css/journal.css";
import React, { Component } from "react";
import JournalEntrys from "./components/JournalEntrys";

class Journal extends Component {
  render() {
    return (
      <div id="journal" className="page page-margin">
        <div className="hero">
          <h1 className="primary-color">A collection of my thoughts</h1>
          <p className="herodesc">
            I wanted to chronicle my thoughts as I progress through my career.
            Stop by every once in a while to see what I’ve been thinking about!
          </p>
        </div>
        {/* <div className='filter h4'>Filter by:</div> */}
        <JournalEntrys />
      </div>
    );
  }
}

export default Journal;
