import React from "react";

const RRH = (props) => {
  const projColor = props.props.projColor;
  return (
    <div>
      <section id="problem">
        <div className="projWidth">
          <h2>Problem</h2>
          <p>
            Hospitals are a stressful place and navigating the space can cause a
            lot of problems to the visitor, the patient and the hospital staff.
            With this in mind we thought about our individual experiences at a
            hospital and thought of 4 major pain points as a visitor:
          </p>
        </div>
        <ul className="fourList">
          <li className="listItem">
            <h6 className="listNum" style={{ color: projColor }}>
              01
            </h6>
            <h6>In an emotional state</h6>
            <p>
              Patients and visitors experience stress and confusion when
              entering a hospital, resulting in emotionally charged actions.
            </p>
          </li>
          <li className="listItem">
            <h6 className="listNum" style={{ color: projColor }}>
              02
            </h6>
            <h6>Complex navigation in hospital</h6>
            <p>
              Hospital spaces are complex and vary in size. This makes
              navigating a hospital extremely difficult even for a returning
              visitor, as pertinent information such as patient location and
              status are always updating.
            </p>
          </li>
          <li className="listItem">
            <h6 className="listNum" style={{ color: projColor }}>
              03
            </h6>
            <h6>Not clear what to do next</h6>
            <p>
              During a hospital stay or visit, after checking in and navigating
              to an area, there is a disconnect when trying to navigate to the
              next location such as a bathroom or lounge.
            </p>
          </li>
          <li className="listItem">
            <h6 className="listNum" style={{ color: projColor }}>
              04
            </h6>
            <h6>Unknown patient information</h6>
            <p>
              As a visitor, knowing where the person you’re visiting is located
              and their current status is critical. There is currently no
              optimized communication link between patient and visitor.
            </p>
          </li>
        </ul>
      </section>
      <section id="research">
        <div className="projWidth">
          <h2>Research</h2>
          <p>
            After putting together our research direction based on the four
            major pain points we identified, we decided to research the hospital
            setting as a whole. We interviewed hospital administration and their
            biggest issue they found was visitors asking this question:
          </p>
          <blockquote style={{ borderLeft: `5px solid ${projColor}` }}>
            <p>"Where do I go?"</p>
          </blockquote>
          <p>
            Based on that statement we decided to limit our scope to a visitor
            who is coming to see a patient, rather than an entire slew of user
            types.
          </p>
          <p>
            We also found that hospitals also run like a business as well. For
            starters, hospitals are required to keep certain information private
            about patients in order to abide to HIPAA laws. Using an AR in this
            kind of setting brings up an awkward conversation about privacy
            protection. Hospitals also need money and thus, desire benefits that
            come in through these kinds of applications.
          </p>
        </div>
        <ul className="fourRow projWidth">
          <li className="rowItem">
            <img src={require("../../assets/RRH/onboarding-01.png")} />
            <h6 style={{ color: projColor }}>Lessen emotional stress</h6>
          </li>
          <li className="rowItem">
            <img src={require("../../assets/RRH/onboarding-02.png")} />
            <h6 style={{ color: projColor }}>Bring benefits to the hospital</h6>
          </li>
          <li className="rowItem">
            <img src={require("../../assets/RRH/onboarding-03.png")} />
            <h6 style={{ color: projColor }}>Inform visitors during a visit</h6>
          </li>
          <li className="rowItem">
            <img src={require("../../assets/RRH/onboarding-04.png")} />
            <h6 style={{ color: projColor }}>Communicate with patient</h6>
          </li>
        </ul>
        <div className="projWidth">
          <p>
            Once all of our research had set us in a solid direction, we decided
            to move onto our user flows. We had a basic idea on how we wanted to
            present our app and created two flow that showed off the main
            essence of our project.
          </p>
          <div className="fullColm">
            <h4>User Flow 01: Onboarding</h4>
            <img src={require("../../assets/RRH/flow-01.png")} />
          </div>
          <div className="fullColm">
            <h4>User Flow 02: Navigating</h4>
            <img src={require("../../assets/RRH/flow-02.png")} />
          </div>
        </div>
      </section>
      <section id="design">
        <div className="projWidth">
          <h2>Design</h2>
          <p>
            We wanted our designs to calm a user with playful interactions and
            illustrations without going too far and making it look childish. We
            used very simple illustrations that visualized the onboarding
            process and set an initial mood for the app. From there, simplified
            illustrations were used for other call outs to further balance the
            mood. We went for a monochromatic design to create a simple and easy
            to view UI with rounded boxes and circular buttons to further the
            friendliness of the app.
          </p>
          <img src={require("../../assets/RRH/styleboards-01.png")} />
        </div>
      </section>
      <section id="solutions">
        <div className="projWidth">
          <h2>Solutions</h2>
          <p>
            Our main goal was to simplify navigating a hospital while fostering
            a friendlier vibe for the user. Using fun and simple animations
            while keeping in mind where UI lived in the space, we were able to
            create an enjoyable navigation experience.
          </p>
        </div>
        <div className="pitchVideo" style={{ backgroundColor: projColor }}>
          <video
            src={require("../../assets/RRH/final-pitch-vid.mp4")}
            type="video/mp4"
            controls
          />
        </div>
        <div className="twocolum projWidth">
          <div className="iphoneMock">
            <img
              alt="iphone mock"
              src={require("../../assets/iphone-mock.png")}
              className="iphone"
            />
            <video
              src={require("../../assets/RRH/onboarding-vid.mp4")}
              type="video/mp4"
              className="phoneVid"
              loop
              autoPlay
              muted
            />
          </div>
          <div className="text">
            <h6>Onboarding</h6>
            <p>
              The introduction to the app should be simple, friendly and not
              overwhelming. By on boarding the user from the start, we are
              giving the user information about the application as well as a
              simplified instruction on what to do after downloading the app.
              This way the user wont download the app and get confused on what
              to do next.
            </p>
          </div>
        </div>
        <div className="twocolum projWidth reverse">
          <div className="iphoneMock">
            <img
              alt="iphone mock"
              src={require("../../assets/iphone-mock.png")}
              className="iphone"
            />
            <video
              src={require("../../assets/RRH/patient-status-vid.mp4")}
              type="video/mp4"
              className="phoneVid"
              loop
              autoPlay
              muted
            />
          </div>
          <div className="text">
            <h6>Patient Status</h6>
            <p>
              The user will always have quick access to the patients status by
              entering a menu on the bottom. With that information on their
              fingertips, they can quickly deduce where their patient is and
              their current status as well as personalized information of the
              visitor, such as parking information and available coupons
              available for use.
            </p>
          </div>
        </div>
        <div className="twocolum projWidth">
          <div className="iphoneMock">
            <img
              alt="iphone mock"
              src={require("../../assets/iphone-mock.png")}
              className="iphone"
            />
            <video
              src={require("../../assets/RRH/navigate-vid.mp4")}
              type="video/mp4"
              className="phoneVid"
              loop
              autoPlay
              muted
            />
          </div>
          <div className="text">
            <h6>Alternative Destinations</h6>
            <p>
              The user will always have control on where they are going. There
              will be an option that will allow for the user to explore other
              locations with-in the hospital, whether it be the nearest
              cafeteria or bathroom. Then it will automatically reroute the user
              to the selected destination.
            </p>
          </div>
        </div>
        <div className="twocolum projWidth reverse">
          <div className="iphoneMock">
            <img
              alt="iphone mock"
              src={require("../../assets/iphone-mock.png")}
              className="iphone"
            />
            <video
              src={require("../../assets/RRH/user-incentive-vid.mp4")}
              type="video/mp4"
              className="phoneVid"
              loop
              autoPlay
              muted
            />
          </div>
          <div className="text">
            <h6>User Incentives</h6>
            <p>
              Throughout the visit users will be provided with coupons to
              further enhance their hospital experience. This will also give a
              user a desire to download and use the app.
            </p>
          </div>
        </div>
        <div className="mocks" style={{ backgroundColor: projColor }}>
          <img
            src={require("../../assets/RRH/final-comps.png")}
            className="projWidth"
          />
        </div>
      </section>
      <section id="conclusion" className="projWidth">
        <h2>Conclusion</h2>
        <p>
          I think that everyone can agree that visiting a hospital is stressful.
          By creating this application, I wanted to understand that process
          better and try to create an experience that is more enjoyable. It also
          allowed me to practice my UI animation and prototyping.
        </p>
      </section>
    </div>
  );
};

export default RRH;
