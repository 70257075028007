import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { client } from "../../client";
import format from "date-fns/format";

const JournalEntrys = () => {
  const [isJournalLoading, setIsJournalLoading] = useState(false);
  const [pages, setPages] = useState([]);

  const cleanPages = useCallback((rawData) => {
    const cleaned = rawData.map((post) => {
      const { sys, fields } = post;
      const { id } = sys;
      const postTitle = fields.title;
      const postDate = format(
        new Date(fields.postDate ? fields.postDate : sys.updatedAt),
        "PPPP"
      );
      const postSlug = fields.slug;
      const postBG = fields.hero.fields.file.url;
      const updatedPost = {
        id,
        postTitle,
        postDate /*, postContent*/,
        postBG,
        postSlug,
      };
      return updatedPost;
    });

    setPages(cleaned);
  }, []);

  const getPages = useCallback(async () => {
    setIsJournalLoading(true);
    try {
      const response = await client.getEntries({ content_type: "blogPost" });
      const responseData = response.items;
      if (responseData) {
        cleanPages(responseData);
      } else {
        setPages([]);
      }
      setIsJournalLoading(false);
    } catch (error) {
      setIsJournalLoading(false);
    }
  }, [cleanPages]);

  useEffect(() => {
    getPages();
  }, [getPages]);

  return (
    <div className="entry-list">
      {pages.map((item) => {
        const { id, postTitle, postDate, postBG, postSlug } = item;
        return (
          <Link key={id} to={"/journal/" + postSlug} className="entry">
            <div
              className="img"
              style={{ backgroundImage: `url(${postBG})` }}
            />
            <div className="entryTitle">
              <b>{postTitle}</b>
            </div>
            <p className="entryDate">{postDate}</p>
          </Link>
        );
      })}
    </div>
  );
};

export default JournalEntrys;
