import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { client } from "../../client";
import { useParams } from "react-router-dom";
import format from "date-fns/format";

const JournalFooter = () => {
  const { slug } = useParams();
  const [isJournalLoading, setIsJournalLoading] = useState(false);
  const [pages, setPages] = useState([]);

  const cleanPages = useCallback((rawData) => {
    const cleaned = rawData.map((post) => {
      const { sys, fields } = post;
      const { id } = sys;
      const postTitle = fields.title;
      const postDate = format(
        new Date(fields.postDate ? fields.postDate : sys.updatedAt),
        "PPPP"
      );
      const postSlug = fields.slug;
      const postBG = fields.hero.fields.file.url;
      const updatedPost = {
        id,
        postTitle,
        postDate /*, postContent*/,
        postBG,
        postSlug,
      };
      return updatedPost;
    });

    setPages(cleaned);
  }, []);

  const removePages = (rawData) => {
    const checked = rawData?.filter((page) => {
      return page.fields.slug !== slug;
    });
    if (checked.length <= 3) {
      return checked;
    } else {
      checked.pop();
      const fixed = checked;
      console.log(fixed);
      return fixed;
    }
  };

  const getPages = useCallback(async () => {
    setIsJournalLoading(true);
    try {
      // console.log(client?'got it':"where is it")
      const response = await client.getEntries({
        content_type: "blogPost",
        limit: 4,
      });
      const responseData = response.items;
      if (responseData) {
        cleanPages(removePages(responseData));
      } else {
        setPages([]);
      }
      setIsJournalLoading(false);
    } catch (error) {
      setIsJournalLoading(false);
    }
  }, [cleanPages]);

  useEffect(() => {
    getPages();
  }, [getPages]);

  return (
    <div id="journalFooter">
      {slug ? (
        <h2 className="primary-color">Some more of my thoughts</h2>
      ) : (
        <div />
      )}
      <div className="entry-list">
        {pages.map((item) => {
          const { id, postTitle, postDate, postBG, postSlug } = item;
          return (
            <Link
              key={id}
              to={"/journal/" + postSlug}
              className="entry"
              onClick={() => {
                window.reload();
              }}
            >
              <div
                className="img"
                style={{ backgroundImage: `url(${postBG})` }}
              />
              <div className="entryTitle">
                <b>{postTitle}</b>
              </div>
              <p className="entryDate">{postDate}</p>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default JournalFooter;
