import React from "react";
import "../css/footer.css";
import "../css/main.css";

const Footer = () => {
  return (
    <div id="footer" className="page-margin">
      <div className="divider" />
      <div className="footer-content">
        <div className="footer-text">
          <div className="social-links">
            <h2 className="primary-color">Where to find me</h2>
            <div className="link-list">
              <a
                href="https://www.linkedin.com/in/alex-seowon-kim/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>LinkedIn</b>
              </a>
              <a
                href="https://dribbble.com/alex-seowon-kim"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Dribbble</b>
              </a>
              <a
                className="last"
                href="https://github.com/alexkim721"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Github</b>
              </a>
            </div>
          </div>
          <div className="info">
            <h3 className="copyright">Alexander Kim - Copyright © 2022</h3>
            <div className="credits c">
              Designed and Coded with love by Alex.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
