import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Connections = (props) => {
  const ref = useRef(null);
  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const projColor = props.props.projColor;
  const [page, setPage] = useState(0);
  const getPage = () => {
    if (page === 1) {
      return <Plan />;
    } else if (page === 2) {
      return <Discover />;
    } else if (page === 3) {
      return <Community />;
    } else if (page === 4) {
      return <Gamification />;
    } else {
      return <Homepage />;
    }
  };

  return (
    <div>
      <section id="problem" className="projWidth">
        <h2>Problem</h2>
        <p>
          Recovery is a difficult journey that many people struggle with.
          Without accessible ways of interacting with patients, hospitals and
          recovery groups have a hard time keeping their patients on their road
          to recovery. There also aren't many accessible resources that can keep
          patients not only on task but engaged.
        </p>
        <div
          className="fullcolmimage"
          style={{ backgroundColor: projColor, padding: "20px 0" }}
        >
          <img
            src={require("../../assets/connections/corgi.png")}
            style={{ width: "50%", margin: "20px 0 40px" }}
          />
        </div>
        <p>
          CHESS health’s solution was to create a mobile phone app, Connections,
          that allows users to continuously track their recovery in engaging
          ways. Users had a wide variety of options from planning their schedule
          with medication and doctor/therapist appointments to engaging with
          others on their own recovery journey through a social media wall.
        </p>
        <p>
          However, as the platform grew and design patterns evolved, Connections
          wasn’t able to keep with current design trends. Because of its dated
          design, navigation between sections was difficult and its lack of
          usability made it a hassle to use. It also had tons of content, but it
          was not presented in an engaging way for the user.
        </p>
      </section>
      <section id="solution" className="projWidth">
        <h2>Solution</h2>
        <p>
          My task was to come into the app and start reworking the app section
          by section to bring it up to modern standards. From the start, I
          created several end goals in mind. Some of these goals were extensions
          from my previous project, envoy.
        </p>
        <ul className="fullColmList">
          <li>
            <h5 style={{ color: projColor }}>01</h5>
            <h6>
              Redesign current sections using similar design patterns defined in
              envoy.
            </h6>
          </li>
          <li>
            <h5 style={{ color: projColor }}>02</h5>
            <h6>Create new requested sections with the same new standards.</h6>
          </li>
          <li>
            <h5 style={{ color: projColor }}>03</h5>
            <h6>
              Introduce new features within existing sections to further the
              user experience.
            </h6>
          </li>
          <li>
            <h5 style={{ color: projColor }}>03</h5>
            <h6>
              Create a friendly atmosphere that gets users excited to use the
              app.
            </h6>
          </li>
        </ul>
        <p>
          Because each section was so complex that they could have served as
          their own separate app, I treated each as their own project. Each
          section had its own set of specified goals and solutions. However they
          all followed similar design patterns to create a harmonious and
          consistent platform.
        </p>
      </section>
      <div ref={ref} className="projWidth divider" />
      <section id="connectionsSections" className="projWidth">
        <div className="connectionsNav">
          <ul>
            <li>
              <button
                onClick={() => {
                  setPage(0);
                }}
                style={
                  page === 0 ? { boxShadow: "inset 0 -5px 0 " + projColor } : {}
                }
              >
                Homepage
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  setPage(1);
                }}
                style={
                  page === 1 ? { boxShadow: "inset 0 -5px 0 #20D8A1" } : {}
                }
              >
                Plan
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  setPage(2);
                }}
                style={
                  page === 2 ? { boxShadow: "inset 0 -5px 0 #12ADDE" } : {}
                }
              >
                Discover
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  setPage(3);
                }}
                style={
                  page === 3 ? { boxShadow: "inset 0 -5px 0 #E332B2" } : {}
                }
              >
                Community
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  setPage(4);
                }}
                style={
                  page === 4 ? { boxShadow: "inset 0 -5px 0 #564CAF" } : {}
                }
              >
                Gamification
              </button>
            </li>
          </ul>
          {/* <div
            className="underLine"
            style={{
              backgroundColor: projColor,
              height: "5px",
              width: "100%",
            }}
          ></div> */}
        </div>
        <div id="pageContent">{getPage()}</div>
        <div className="connectionsNavFooter">
          <ul>
            <li>
              <button
                onClick={() => {
                  setPage(0);
                  handleClick();
                }}
                style={page === 0 ? { color: projColor } : {}}
              >
                Homepage
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  setPage(1);
                  handleClick();
                }}
                style={page === 1 ? { color: " #20D8A1" } : {}}
              >
                Plan
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  setPage(2);
                  handleClick();
                }}
                style={page === 2 ? { color: "#12ADDE" } : {}}
              >
                Discover
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  setPage(3);
                  handleClick();
                }}
                style={page === 3 ? { color: "#E332B2" } : {}}
              >
                Community
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  setPage(4);
                  handleClick();
                }}
                style={page === 4 ? { color: "#564CAF" } : {}}
              >
                Gamification
              </button>
            </li>
            <li className="line" />
          </ul>
        </div>
      </section>

      {/* <hr className="projWidth" /> */}
      <section id="conclusion" className="projWidth">
        <h2>Conclusion</h2>
        <p>
          The Connections app was the reason why I was hired for the position
          and what I had been working on from day 1 with CHESS Health. It
          allowed me to learn about a unique group of users that had their own
          specific use cases and needs. I learned about accessibility that
          molded my design philosophy into what it is today.
        </p>
        <p>
          I was also able to hone my strengths such as illustration and UI
          Design at a high level. While envoy was my baby because of my
          involvement from start to finish, I consider Connections my magnum
          opus of my time in CHESS. Seeing it go live gives me a great sense of
          pride knowing that my designs are being used by some extremely
          vulnerable people. It inspires me to continue working and improving
          with the mindset that somewhere, someone is using something I created
          to better themselves.
        </p>
      </section>
    </div>
  );
};

export const Homepage = () => {
  const projColor = "#50A3E0";
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div id="Homepage" className="pageSection">
      <p>
        The homepage was actually one of the last sections I worked on but its
        design was one of the first things I thought about when entering CHESS.
        As the first thing a user would see when starting the app, it had to
        send a good impression as well as reflect on how the rest of the app
        would feel.
      </p>
      <div className="twocolum projWidth" style={{ margin: "60px auto" }}>
        <div className="iphoneMock">
          <img
            src={require("../../assets/connections/old-homepage.png")}
            style={{ border: "1px solid #f5f5f5", width: "80%" }}
          />
        </div>
        <div className="text">
          <p>
            The original homepage was designed more as a navigational menu with
            huge colorful buttons that gave minimal information. There was a lot
            of unused screen real-estate without really giving much useful
            information to the user.
          </p>
        </div>
      </div>

      <p>
        For the redesign, I wanted the homepage to serve a greater purpose.
        Looking at other homepage apps, I noticed they not only highlighted key
        features, but also gave more information and content that the user would
        be interested in.
      </p>
      {/* <div className="widecolm">
        <img src={require("../../assets/envoy/Onboarding.png")} />
        <img src={require("../../assets/envoy/Onboarding-1.png")} />
        <img src={require("../../assets/envoy/Onboarding-2.png")} />
        <img src={require("../../assets/envoy/Onboarding-3.png")} />
        <img src={require("../../assets/envoy/Onboarding-4.png")} />
      </div> */}
      <p>
        While creating the new designs there were some parameters to keep in
        mind because of the nature of the app:
      </p>
      <ul className="fullColmList">
        <li>
          <h5 style={{ color: projColor }}>01</h5>
          <h6>
            The app is always growing with new sections and features being
            introduced.
          </h6>
        </li>
        <li>
          <h5 style={{ color: projColor }}>02</h5>
          <h6>
            One of the main draws to the app is its customization on a provider
            level.
          </h6>
        </li>
        <li>
          <h5 style={{ color: projColor }}>03</h5>
          <h6>There is a wide range of users in different age groups.</h6>
        </li>
      </ul>
      <p>
        On top of redesigning the homepage, I also designed the onboarding pages
        that the user would see when they first logged into the app.
      </p>
      <p>
        While researching onboarding pages, I noticed that most of the aesthetic
        designs incorporated illustrations to introduce the users to its key
        features in a fun way.
        {/* <div className="widecolm">
        <img src={require("../../assets/envoy/Onboarding.png")} />
        <img src={require("../../assets/envoy/Onboarding-1.png")} />
        <img src={require("../../assets/envoy/Onboarding-2.png")} />
        <img src={require("../../assets/envoy/Onboarding-3.png")} />
        <img src={require("../../assets/envoy/Onboarding-4.png")} />
      </div> */}
        I wanted to do something similar. By using bright, colorful
        illustrations, I could set the tone of the rest of the app.
      </p>
      <section id="homepageDesign">
        <h2>Designs</h2>
        <div className="twocolum projWidth">
          <div className="iphoneMock">
            {/* <img
              alt="iphone mock"
              src={require("../../assets/iphone-flat-mock.pn")}
              className="iphone"
            /> */}
            <Slider {...slickSettings}>
              <img src={require("../../assets/connections/login-0.png")} />
              <img src={require("../../assets/connections/login-1.png")} />
              <img src={require("../../assets/connections/login-2.png")} />
              <img src={require("../../assets/connections/login-3.png")} />
            </Slider>
          </div>
          <div className="text">
            <h6>Login</h6>
            <p>
              For the initial Login pages, I wanted to keep the length as short
              as possible while getting all the necessary information. Because
              of the nature of the users, I didn’t want to lose their attention
              because of a difficult account creation process.
            </p>
          </div>
        </div>
        <div
          className="twocolum projWidth reverse"
          style={{ margin: "60px auto" }}
        >
          <div className="iphoneMock">
            {/* <img
              alt="iphone mock"
              src={require("../../assets/iphone-flat-mock.pn")}
              className="iphone"
            /> */}
            <Slider {...slickSettings}>
              <img src={require("../../assets/connections/onboarding-0.png")} />
              <img src={require("../../assets/connections/onboarding-1.png")} />
              <img src={require("../../assets/connections/onboarding-2.png")} />
              <img src={require("../../assets/connections/onboarding-3.png")} />
            </Slider>
          </div>
          <div className="text">
            <h6>Onboarding</h6>
            <p>
              For the onboarding pages, I kept the design simple by pointing out
              some key features with the added option to set up their account
              with the necessary information for each of the sections. It also
              includes a matching illustration that borrows an asset from the
              described section to link it visually.
            </p>
          </div>
        </div>
        <div className="twocolum projWidth" style={{ margin: "60px auto" }}>
          <div className="iphoneMock">
            {/* <img
              alt="iphone mock"
              src={require("../../assets/iphone-flat-mock.pn")}
              className="iphone"
            /> */}
            <Slider {...slickSettings}>
              <img src={require("../../assets/connections/homepage-0.png")} />
              <img src={require("../../assets/connections/homepage-1.png")} />
              <img src={require("../../assets/connections/homepage-2.png")} />
              <img src={require("../../assets/connections/homepage-3.png")} />
            </Slider>
          </div>
          <div className="text">
            <h6>Homepage</h6>
            <p>
              The new homepage consisted of all the features of the previous
              design plus more. In an attempt to prevent alienation of precious
              users, I kept all the sections navigation on the homepage with
              simple iconography and a more conservative use of color.
            </p>
            <p>
              I also wanted to emphasize its modularity with card-like buttons
              that could be adjusted in size. That way, new features and custom
              buttons could be added freely without altering to design.
            </p>
          </div>
        </div>
        <div
          className="twocolum projWidth reverse"
          style={{ margin: "60px auto" }}
        >
          <div className="iphoneMock">
            {/* <img
              alt="iphone mock"
              src={require("../../assets/iphone-flat-mock.pn")}
              className="iphone"
            /> */}
            <Slider {...slickSettings}>
              <img
                src={require("../../assets/connections/homepage-top-0.png")}
              />
              <img
                src={require("../../assets/connections/homepage-top-1.png")}
              />
              <img
                src={require("../../assets/connections/homepage-top-2.png")}
              />
            </Slider>
          </div>
          <div className="text">
            <h6>Homepage: Upper Section</h6>
            <p>
              With the increased screen real-estate, I wanted to bring to the
              forefront some new features that would bring more practicality to
              the homepage. With the addition of gamification, there would now
              be a quick overview on daily user goals. It would also house the
              user’s self defined main motivation as a way to remind them why
              they are on their journey.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
export const Plan = () => {
  const projColor = "#20D8A1";
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div id="Plan" className="pageSection">
      <p>
        The Plan section housed some of the most complex functionality of the
        app. Here, the user could check their schedule which included a
        multitude of different events and tasks such as medications and
        meetings. Users were also encouraged to fill out surveys in this section
        to record and monitor their recovery journey. Finally, there were some
        user driven features as well such as journaling and goals to create a
        personalized experience.
      </p>
      <p>
        The redesign of the Plan section was actually my first big project in
        relation to the Connections app. Here, I defined a lot of design
        patterns that I would try to use throughout the app, such as modular
        cards and colorful illustrations.
      </p>

      <div className="widecolm">
        <img
          src={require("../../assets/connections/plan-section-0.png")}
          style={{ borderRadius: "10px", width: "22%" }}
        />
        <img
          src={require("../../assets/connections/plan-section-1.png")}
          style={{ borderRadius: "10px", width: "22%" }}
        />
        <img
          src={require("../../assets/connections/plan-section-2.png")}
          style={{ borderRadius: "10px", width: "23%" }}
        />
        <img
          src={require("../../assets/connections/plan-section-3.png")}
          style={{ borderRadius: "10px", width: "22%" }}
        />
      </div>
      <p>
        The previous plan page was extremely bare bones and used primarily for
        its function. However, when asking users and providers on their
        experience with using the section, we found that most people avoided
        using it all together. There wasn’t a lot of value in using the section
        over other apps that could do the same while being more familiar with
        the user.
      </p>
      {/* <div className="widecolm">
        <img src={require("../../assets/envoy/Onboarding.png")} />
        <img src={require("../../assets/envoy/Onboarding-1.png")} />
        <img src={require("../../assets/envoy/Onboarding-2.png")} />
        <img src={require("../../assets/envoy/Onboarding-3.png")} />
        <img src={require("../../assets/envoy/Onboarding-4.png")} />
      </div> */}

      <h2>Designs</h2>
      <p>
        When looking at the section, the functionality was there, but it wasn’t
        set up in a friendly and usable way so I started with that in mind.
      </p>
      <div className="widecolm">
        <img src={require("../../assets/connections/Plan-1.png")} />
        <img src={require("../../assets/connections/Plan-2.png")} />
        <img src={require("../../assets/connections/Plan-3.png")} />
        <img src={require("../../assets/connections/Plan.png")} />
      </div>
      <p>
        With defining, colorful illustrations, I could draw attention to some
        key features as well as make a practical landing hub for the section
        that would house useful information without overwhelming them.
      </p>
      <p>
        We also added features that allowed the user to directly connect with
        their providers to schedule dates for meetings and directly video chat
        through the app. With a singular hub for everything they need, it would
        reduce the need to jump between apps as well as create a bigger
        incentive for potential providers.
      </p>
      <h4>Events</h4>
      <p>
        The events page houses scheduled information created for and by the
        user. It also takes the user to video meetings that have been directly
        set up by providers.
      </p>

      <div className="twocolum projWidth" style={{ margin: "60px auto" }}>
        <div className="iphoneMock">
          {/* <img
              alt="iphone mock"
              src={require("../../assets/iphone-flat-mock.pn")}
              className="iphone"
            /> */}
          <Slider {...slickSettings}>
            <img src={require("../../assets/connections/Reminders.png")} />
            <img src={require("../../assets/connections/Reminders-1.png")} />
            <img src={require("../../assets/connections/Reminders-2.png")} />
            <img src={require("../../assets/connections/Reminders-3.png")} />
          </Slider>
        </div>
        <div className="text">
          <h6>Reminders</h6>
          <p>
            Within the Reminders page, it displays a calendar that shows all
            appointments, surveys, medications and other reminders for users on
            a day to day basis. Event cards are color coded with tabs that
            relate to the type of content it holds.
          </p>
        </div>
      </div>
      <div
        className="twocolum projWidth reverse"
        style={{ margin: "60px auto" }}
      >
        <div className="iphoneMock">
          {/* <img
              alt="iphone mock"
              src={require("../../assets/iphone-flat-mock.pn")}
              className="iphone"
            /> */}
          <Slider {...slickSettings}>
            <img src={require("../../assets/connections/event-card-0.png")} />
            <img src={require("../../assets/connections/event-card-2.png")} />
            <img src={require("../../assets/connections/event-card-3.png")} />
          </Slider>
        </div>
        <div className="text">
          <h6>Event Cards</h6>
          <p>
            Tapping into an event card opens into a modal that gives further
            information and action for that event. Event cards are defined by
            tags that indicate parameters of the event, such as whether or not
            the event has been canceled or can be seen by the user's appointed
            doctor.
          </p>
        </div>
      </div>
      <div className="twocolum projWidth" style={{ margin: "60px auto" }}>
        <div className="iphoneMock">
          {/* <img
              alt="iphone mock"
              src={require("../../assets/iphone-flat-mock.pn")}
              className="iphone"
            /> */}
          <Slider {...slickSettings}>
            <img src={require("../../assets/connections/new-event-3.png")} />
            <img src={require("../../assets/connections/new-event-0.png")} />
            <img src={require("../../assets/connections/new-event-1.png")} />
            <img src={require("../../assets/connections/new-event-2.png")} />
          </Slider>
        </div>
        <div className="text">
          <h6>Creating an Event</h6>
          <p>
            The user has the ability to create and define their own events,
            complete with times, descriptions and repetition. These events are
            also completely editable if the user desires to make changes after
            the event has been created.
          </p>
        </div>
      </div>
      <h4>Journal</h4>
      <p>
        The Journal page houses different entries the user can make about their
        recovery journey. Previously, the journal section was one of the least
        utilized sections because of how bare it was of content. They were
        simply just a collection of text documents.
      </p>
      <p>
        To create a more tangible but simple way of organizing and viewing the
        entries, I added an emotional scale that would record the emotional
        state of the user when they create a new entry.
      </p>
      <div className="fullcolmimage" style={{ backgroundColor: projColor }}>
        <img src={require("../../assets/connections/journal-scale.png")} />
      </div>
      <p>
        I also added an optional field for prompts when a user might have a
        little trouble figuring out something to write about. The prompts would
        be defined by our trained customer success team for relevant content.
      </p>
      <div className="widecolm">
        <img src={require("../../assets/connections/Journal.png")} />
        <img src={require("../../assets/connections/Journal-1.png")} />
        <img src={require("../../assets/connections/Journal-2.png")} />
        <img src={require("../../assets/connections/Journal-3.png")} />
        <img src={require("../../assets/connections/Journal-4.png")} />
      </div>
      <h4>Surveys</h4>
      <p>
        Finally the surveys section was the last part I worked on for Plan.
        Providers are constantly looking for ways to retrieve important data
        from their users to not only record precious information but also look
        for patterns and trends to improve their overall process. This page
        provides the perfect opportunity for users and providers to interact in
        a unique way.
      </p>
      <div className="twocolum projWidth" style={{ margin: "60px auto" }}>
        <div className="iphoneMock">
          {/* <img
              alt="iphone mock"
              src={require("../../assets/iphone-flat-mock.pn")}
              className="iphone"
            /> */}
          <Slider {...slickSettings}>
            <img src={require("../../assets/connections/Survey.png")} />
            <img src={require("../../assets/connections/Survey-2.png")} />
            <img src={require("../../assets/connections/Survey-1.png")} />
          </Slider>
        </div>
        <div className="text">
          <h6>Tracking Progress</h6>
          <p>
            When first entering the surveys page, the user is greeted with a
            graph that tracks the user's previous survey results. These results
            show tangible progress and relapses that help users make active
            changes and decisions for themselves.
          </p>
        </div>
      </div>
      <div
        className="twocolum projWidth reverse"
        style={{ margin: "60px auto" }}
      >
        <div className="iphoneMock">
          {/* <img
              alt="iphone mock"
              src={require("../../assets/iphone-flat-mock.pn")}
              className="iphone"
            /> */}
          <Slider {...slickSettings}>
            <img src={require("../../assets/connections/Survey-Type.png")} />
            <img src={require("../../assets/connections/Survey-Type-1.png")} />
            <img src={require("../../assets/connections/Survey-Type-2.png")} />
          </Slider>
        </div>
        <div className="text">
          <h6>Survey Types</h6>
          <p>
            Surveys can be styled differently based on what they are used for.
            With the different styling, users can more easily differentiate
            surveys. It also gives the opportunity to add some more creative
            flare to engage the user.
          </p>
        </div>
      </div>
      <div className="twocolum projWidth" style={{ margin: "60px auto" }}>
        <div className="iphoneMock">
          {/* <img
              alt="iphone mock"
              src={require("../../assets/iphone-flat-mock.pn")}
              className="iphone"
            /> */}
          <Slider {...slickSettings}>
            <img
              src={require("../../assets/connections/Survey-Results-1.png")}
            />
            <img src={require("../../assets/connections/Survey-Results.png")} />
            <img
              src={require("../../assets/connections/Survey-Results-2.png")}
            />
          </Slider>
        </div>
        <div className="text">
          <h6>Results and Recommendations</h6>
          <p>
            At the end of the survey, it will show a results page that directly
            compares the users results with the previous results from the same
            survey. This page shows a more in-depth breakdown of results.
          </p>
          <p>
            Afterwards, a recommendations page shows some options the user can
            take to further their recovery journey.
          </p>
        </div>
      </div>
    </div>
  );
};
export const Discover = () => {
  const projColor = "#12ADDE";
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div id="Discover" className="pageSection">
      <p>
        The Discover section was heavily underutilized by users because of how
        content was organized. Its purpose was to give users resources that
        could aid in their recovery as well as allow them to upload and use
        their own personal content as a driving force for their recovery.
      </p>
      <p>
        Initially, the content was organized in long, unengaging lists with no
        way to filter for specific content. There were many types of content but
        no way to see what kind of content they were. Motivations were also very
        under utilized, as they were contained in a singular page with no
        meaningful way to access them.
      </p>
      <h2>Designs</h2>
      <p>
        For my redesign, I wanted to bring more user incentive to interact with
        this section by not only focusing on potentially relevant content, but
        also using cute illustrations to further a friendly atmosphere.
      </p>
      <div className="twocolum projWidth">
        <div className="iphoneMock">
          {/* <img
              alt="iphone mock"
              src={require("../../assets/iphone-flat-mock.pn")}
              className="iphone"
            /> */}
          <Slider {...slickSettings}>
            <img src={require("../../assets/connections/Discover.png")} />
            <img src={require("../../assets/connections/Discover-2.png")} />
            {/* <img src={require("../../assets/connections/Discover-1.png")} /> */}
          </Slider>
        </div>
        <div className="text">
          <h6>Focused Content</h6>
          <p>
            Within the hub page, I focused on showing the user their chosen
            motivations as well as a provider created quote called “Thought of
            the Day”. That way, if a user had yet to upload a motivation,
            relevant and inspiring content would still be displayed.
          </p>
          <p>
            Featured content also would direct the users attention to new
            content uploaded by providers. That way there wouldn't be an
            overwhelming amount of content to navigate through on entry.
          </p>
        </div>
      </div>
      <div className="twocolum projWidth reverse">
        <div className="iphoneMock">
          {/* <img
              alt="iphone mock"
              src={require("../../assets/iphone-flat-mock.pn")}
              className="iphone"
            /> */}
          <Slider {...slickSettings}>
            <img src={require("../../assets/connections/Discover-3.png")} />
            <img src={require("../../assets/connections/Discover-7.png")} />
          </Slider>
        </div>
        <div className="text">
          <h6>Motivations</h6>
          <p>
            The motivation grid was left mostly intact with its previous
            interactions with the addition of new illustrations for the
            different types of content added and a main motivation a user can
            select for the homepage.
          </p>
        </div>
      </div>

      <div className="twocolum projWidth">
        <div className="iphoneMock">
          {/* <img
              alt="iphone mock"
              src={require("../../assets/iphone-flat-mock.pn")}
              className="iphone"
            /> */}
          <Slider {...slickSettings}>
            <img src={require("../../assets/connections/Discover-4.png")} />
            <img src={require("../../assets/connections/Discover-5.png")} />
            <img src={require("../../assets/connections/Discover-6.png")} />
          </Slider>
        </div>
        <div className="text">
          <h6>Exploring Content</h6>
          <p>
            The content library was revamped for clearer communication of a
            large amount of content. Categories would display what kinds of
            content it holds as well as if a new item had been added. Content
            items now have clear illustrations to show what type they are along
            with a small heart icon to show if it had been favorited by the
            user.
          </p>
        </div>
      </div>

      <div className="twocolum projWidth reverse">
        <div className="iphoneMock">
          {/* <img
              alt="iphone mock"
              src={require("../../assets/iphone-flat-mock.pn")}
              className="iphone"
            /> */}
          <Slider {...slickSettings}>
            <img src={require("../../assets/connections/Discover-8.png")} />
            <img src={require("../../assets/connections/Discover-9.png")} />
          </Slider>
        </div>
        <div className="text">
          <h6>Searching & Filtering</h6>
          <p>
            A new search function was also added for the content library to
            filter through content quickly. Toggles for content type and a
            search bar are the only filters at the time to keep searches simple
            and quick.
          </p>
        </div>
      </div>
      <p>
        This section was the page that had the least amount of work done.
        Because of the agile workflow and other priorities, Discover never truly
        got the time it needed to be fully developed. However, I am glad I was
        able to create a few mocks before moving on to give a good base for the
        day it does get the proper attention it deserves.
      </p>
    </div>
  );
};
export const Community = () => {
  const projColor = "#2973E2";
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div id="Community" className="pageSection">
      <p>
        The journey to recovery can seem long and lonely at times. Without
        regular communication with others who are going through or have been
        through similar struggles, patients could feel isolated.
      </p>
      <p>
        Our solution was to create a global communication hub for users to
        interact with each other on a bigger scale instead of being locked into
        a singular group. Community is a forum style message board where users
        can interact with others within the same recovery group(s) and staff
        members.
      </p>
      <div
        className="fullcolmimage"
        style={{ backgroundColor: "#FFF1E6", padding: "20px 0" }}
      >
        <img
          src={require("../../assets/connections/community-group.png")}
          style={{ width: "50%", margin: "20px 0 40px" }}
        />
      </div>
    </div>
  );
};
export const Gamification = () => {
  const projColor = "#564CAF";
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div id="Gamification" className="pageSection">
      <img
        src={require("../../assets/construction.png")}
        style={{ width: "50%" }}
      />
    </div>
  );
};

export default Connections;
