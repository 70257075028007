import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { client } from "../../client";

const ProjectBanners = () => {
  const [isProjectsLoading, setIsProjectLoading] = useState(false);
  const [projects, projectsData] = useState([]);

  const cleanProjects = useCallback((rawData) => {
    const cleaned = rawData.map((project) => {
      const { sys, fields } = project;
      const { id } = sys;
      const projectTitle = fields.projectTitle;
      const projectDesc = fields.projectDesc;
      const projectColor = fields.projectColor;
      const projectRoles = fields.projectRoles;
      const projectSlug = fields.slug;
      const projectImg = fields.heroImage.fields.file.url;
      const left = fields.left;
      const order = fields.order;
      const updatedData = {
        id,
        projectTitle,
        projectDesc,
        projectColor,
        projectRoles,
        projectSlug,
        projectImg,
        left,
        order,
      };
      return updatedData;
    });
    cleaned.sort((a, b) => a.order - b.order);

    projectsData(cleaned);
  }, []);

  const getProjectData = useCallback(async () => {
    try {
      const response = await client.getEntries({
        content_type: "project",
        "fields.hidden": false,
      });
      const responseData = response.items;
      console.log(response);
      if (responseData) {
        cleanProjects(responseData);
      } else {
        projectsData(responseData);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getProjectData();
  }, [getProjectData]);

  return (
    <div className="projects">
      {projects.map((item) => {
        const {
          id,
          projectTitle,
          projectDesc,
          projectColor,
          projectRoles,
          projectSlug,
          projectImg,
          left,
        } = item;
        return (
          <Link
            key={id}
            to={"/work/" + projectSlug}
            className={left ? "project left" : "project"}
            style={{ backgroundColor: projectColor }}
          >
            <img src={projectImg} alt={projectTitle} className="image" />
            <div className="project-text">
              <h2>{projectTitle}</h2>
              <div className="project-roles">
                {projectRoles.map((role) => {
                  return (
                    <p key={role}>
                      <b>{role}</b>
                    </p>
                  );
                })}
              </div>
              <p>{projectDesc}</p>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default ProjectBanners;
