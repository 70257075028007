import React, { useState, useEffect, useCallback } from "react";
import { client } from "../../client";

const Jobhistory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState([]);

  const cleanHistory = useCallback((rawData) => {
    const cleanJobs = rawData.map((job) => {
      const { sys, fields } = job;
      const { id } = sys;
      const jobTitle = fields.title;
      const jobCompany = fields.company;
      const jobDesc = fields.desc;
      const jobLength = fields.length;
      const updatedJob = { id, jobTitle, jobCompany, jobDesc, jobLength };
      return updatedJob;
    });

    setHistory(cleanJobs);
  }, []);

  const getHistory = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await client.getEntries({ content_type: "job" });
      const responseData = response.items;
      if (responseData) {
        cleanHistory(responseData);
      } else {
        setHistory([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [cleanHistory]);

  useEffect(() => {
    getHistory();
  }, [getHistory]);

  console.log(history);

  return (
    <div className="jobs">
      {history
        .slice(0)
        .reverse()
        .map((item) => {
          const { id, jobCompany, jobTitle, jobLength, jobDesc } = item;
          return (
            <div key={id} className="job">
              <div className="info">
                <p>
                  <b>{jobCompany}</b>
                </p>
                <div className="divider" />
                <p>
                  <b>{jobTitle}</b>
                </p>
                <div className="divider" />
                <p>
                  <b>{jobLength}</b>
                </p>
              </div>
              <p className="desc">{jobDesc}</p>
            </div>
          );
        })}
    </div>
  );
};

export default Jobhistory;
