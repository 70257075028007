import Home from "./pages/home";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import About from "./pages/about";
import Journal from "./pages/journal";
import Error from "./pages/error";
import Navbar from "./pages/components/Navbar";
import Footer from "./pages/components/Footer";
import Entry from "./pages/components/Entry";
import React from "react";
import Project from "./pages/project";
import ScrollToTop from "./pages/components/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/journal" element={<Journal />} />
        <Route exact path="/journal/:slug" element={<Entry />} />
        <Route exact path="/work/:slug" element={<Project />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
